import React, { useEffect, useState } from 'react';
import getCwpMeta from '../../providers/cwpMetaProvider';
import { getLocale } from '../../providers/languageProvider';
import { getLocaleTranslator, setPreferredLanguage } from '../../util/i18n/i18nService';
import './toggle.scss';

const ZevLanguageToggle = () => {
  const [language, setLanguage] = useState(null);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [country, setCountry] = useState('us');

  const getTranslation = getLocaleTranslator().t;

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [metaData, localeData] = await Promise.all([getCwpMeta(), getLocale()]);
        initializeLanguageOptions(metaData, localeData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    loadInitialData();
  }, []);

  const initializeLanguageOptions = (metaData, localeData) => {
    const userCountry = localeData?.userCountry || 'us';
    const zevBatteryLanguageStored = localStorage.getItem('zevBatteryLanguage');

    setCountry(userCountry);
    if (zevBatteryLanguageStored) {
      setLanguage(zevBatteryLanguageStored);
    } else {
      setLanguage(localeData?.userLanguage || 'en');
      localStorage.setItem('zevBatteryLanguage', localeData?.userLanguage || 'en');
    }

    const languageMappings = metaData?.static?.languageToCountryMapping;
    const filteredLanguages = languageMappings.find(
      mapping => mapping.code.toLowerCase() === userCountry.toLowerCase()
    );

    const langOptions = filteredLanguages.languages;
    setLanguageOptions(langOptions);
  };

  const changeLocale = langCode => {
    setLanguage(langCode);
    setPreferredLanguage({ userCountry: country, userLanguage: langCode });
    const zevBatteryLanguageStored = localStorage.getItem('zevBatteryLanguage');
    if (langCode !== zevBatteryLanguageStored) {
      localStorage.setItem('zevBatteryLanguage', langCode);
    }
  };

  if (languageOptions.length <= 1) return <div></div>;

  return (
    <div aria-label={getTranslation('language_toggle.languageSwitchLabel')} className='languageToggle text-white'>
      <ul>
        {languageOptions.map((languageOption, index) => {
          let id = languageOption.code + '_' + index;
          let isActiveLanguage = language === languageOption.code;

          if (isActiveLanguage) {
            return (
              <li key={id}>
                <span className='selected'>{languageOption.code.toUpperCase()}</span>
              </li>
            );
          }

          return (
            <li key={id}>
              <a
                href='javascript:void(0)'
                aria-label={getTranslation(`language_toggle.languageSwitchTitle.${languageOption.code.toUpperCase()}`)}
                title={getTranslation(`language_toggle.languageSwitchTitle.${languageOption.code.toUpperCase()}`)}
                onClick={() => {
                  changeLocale(languageOption.code.toLowerCase());
                }}
              >
                {languageOption.code.toUpperCase()}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ZevLanguageToggle;
