import React from 'react';
import './App.scss';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

/* import Faq from './components/carnet/faq/FaqHome';
import AccountFaq from './components/carnet/faq/AccountFaq';
import Shop from './components/carnet/faq/Shop';
import AddOn from './components/carnet/faq/Addon';
import EgolfFaq from './components/carnet/faq/EGolfFaq';
import Guardian from './components/carnet/faq/FamilyGuardianFaq';
import MyQFaq from './components/carnet/faq/MyQFaq';
import Message from './components/carnet/faq/MessageFaq';
import Navigate from './components/carnet/faq/NavigateFaq';
import Plan from './components/carnet/faq/PlanFaq';
import Remote from './components/carnet/faq/RemoteFaq';
import VHR from './components/carnet/faq/VHRFaq'; */

import FAQCA from './components/carnet/faqCanada/faqCAhome';
import AccountFaqCA from './components/carnet/faqCanada/AccountFaqCA';
import GuardianCA from './components/carnet/faqCanada/FamilyGuardianFaqCA';
import MessageCA from './components/carnet/faqCanada/MessageFaqCA';
import NavigateCA from './components/carnet/faqCanada/NavigateFaqCA';
import PlanCA from './components/carnet/faqCanada/PlanFaqCA';
import RemoteCA from './components/carnet/faqCanada/RemoteFaqCA';
import VHRCA from './components/carnet/faqCanada/VHRFaqCA';
import FaqCAFR from './components/carnet/faqCanadafr/faqCAhome';
import AccountFaqCAFR from './components/carnet/faqCanadafr/AccountFaqCA';
import GuardianCAFR from './components/carnet/faqCanadafr/FamilyGuardianFaqCA';
import MessageCAFR from './components/carnet/faqCanadafr/MessageFaqCA';
import NavigateCAFR from './components/carnet/faqCanadafr/NavigateFaqCA';
import PlanCAFR from './components/carnet/faqCanadafr/PlanFaqCA';
import RemoteCAFR from './components/carnet/faqCanadafr/RemoteFaqCA';
import VHRCAFR from './components/carnet/faqCanadafr/VHRFaqCA';
import Banner from './components/carnet/theme/banner';
import VideoCA from './components/carnet/marketingVideoCA/VideoHome';
import VideoCAFR from './components/carnet/marketingVideoFR/VideoHome';
import { VWCOM_CARNETAPP_FAQ_URL } from './constants/carnet_constants';

const STATIC_AUTHLESS_URL_ROUTES = [
  'en/us/account-faq',
  'en/us/shop-faq',
  'en/us/add-on-faq',
  'en/us/faq',
  'en/us/remote-services-faq',
  'en/us/message-center-faq',
  'en/us/plan-and-pricing-faq',
  'en/us/family-guardian-faq',
  'en/us/myq-faq',
  'en/us/navigate-faq',
  'en/us/vhr-faq',
  'en/us/e-golf-faq',
  'en/ca/faq',
  'en/ca/account-faq-ca',
  'en/ca/remote-services-faq-ca',
  'en/ca/message-center-faq-ca',
  'en/ca/plan-and-pricing-faq-ca',
  'en/ca/family-guardian-faq-ca',
  'en/ca/navigate-faq-ca',
  'en/ca/vhr-faq-ca',
  'en/ca/marketing-video-ca',
  'fr/ca/faq',
  'fr/ca/account-faq-ca',
  'fr/ca/remote-services-faq-ca',
  'fr/ca/message-center-faq-ca',
  'fr/ca/plan-and-pricing-faq-ca',
  'fr/ca/family-guardian-faq-ca',
  'fr/ca/navigate-faq-ca',
  'fr/ca/vhr-faq-ca',
  'fr/ca/marketing-video-ca-fr',
  'plan-pricing/modelyr-2021-ev',
  'plan-pricing/modelyr-2021',
  'plan-pricing/modelyr-2020-2023',
  'plan-pricing/modelyr-2022-ev',
  'modelyr2014-2019',
  'zev/vw/batteryinfo',
  'zev/audi/batteryinfo',
  'zev/bentley/batteryinfo'
];
const StaticRoutes = props => {
  return (
    <Switch>
      {/*       <Route
        path='/en/us/faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <Faq />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/*       <Route
        path='/en/us/account-faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <AccountFaq />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/account-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/*       <Route
        path='/en/us/shop-faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <Shop />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/shop-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/*       <Route
        path='/en/us/add-on-faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <AddOn />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/add-on-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/*       <Route
        path='/en/us/remote-services-faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <Remote />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/remote-services-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/*       <Route
        path='/en/us/message-center-faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <Message />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/message-center-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/* <Route
        path='/en/us/plan-and-pricing-faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <Plan />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/plan-and-pricing-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/*       <Route
        path='/en/us/family-guardian-faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <Guardian />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/family-guardian-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/*       <Route
        path='/en/us/myq-faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <MyQFaq />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/myq-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />

      {/*       <Route
        path='/en/us/navigate-faq'
        render={() => (
          <>
            {' '}
            <Banner locale={props.locale} />
            <Navigate />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/navigate-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/*       <Route
        path='/en/us/vhr-faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <VHR />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/vhr-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />
      {/*       <Route
        path='/en/us/e-golf-faq'
        render={() => (
          <>
            {' '}
            <Banner locale={props.locale} />
            <EgolfFaq />
          </>
        )}
      /> */}
      {/* CR-371 redirect */}
      <Route
        path='/en/us/e-golf-faq'
        component={() => {
          window.location.href = VWCOM_CARNETAPP_FAQ_URL;
        }}
      />

      <Route
        path='/en/ca/marketing-video-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <VideoCA />
          </>
        )}
      />
      <Route
        path='/fr/ca/marketing-video-ca-fr'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <VideoCAFR />
          </>
        )}
      />
      <Route
        path='/en/ca/faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <FAQCA />
          </>
        )}
      />
      <Route
        path='/en/ca/account-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <AccountFaqCA />
          </>
        )}
      />
      <Route
        path='/en/ca/remote-services-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <RemoteCA />
          </>
        )}
      />
      <Route
        path='/en/ca/message-center-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <MessageCA />
          </>
        )}
      />
      <Route
        path='/en/ca/plan-and-pricing-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <PlanCA />
          </>
        )}
      />
      <Route
        path='/en/ca/family-guardian-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <GuardianCA />
          </>
        )}
      />
      <Route
        path='/en/ca/navigate-faq-ca'
        render={() => (
          <>
            {' '}
            <Banner locale={props.locale} />
            <NavigateCA />
          </>
        )}
      />
      <Route
        path='/en/ca/vhr-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <VHRCA />
          </>
        )}
      />
      <Route
        path='/fr/ca/faq'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <FaqCAFR />
          </>
        )}
      />
      <Route
        path='/fr/ca/account-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <AccountFaqCAFR />
          </>
        )}
      />
      <Route
        path='/fr/ca/remote-services-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <RemoteCAFR />
          </>
        )}
      />
      <Route
        path='/fr/ca/message-center-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <MessageCAFR />
          </>
        )}
      />
      <Route
        path='/fr/ca/plan-and-pricing-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <PlanCAFR />
          </>
        )}
      />
      <Route
        path='/fr/ca/family-guardian-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <GuardianCAFR />
          </>
        )}
      />
      <Route
        path='/fr/ca/navigate-faq-ca'
        render={() => (
          <>
            {' '}
            <Banner locale={props.locale} />
            <NavigateCAFR />
          </>
        )}
      />
      <Route
        path='/fr/ca/vhr-faq-ca'
        render={() => (
          <>
            <Banner locale={props.locale} />
            <VHRCAFR />
          </>
        )}
      />
    </Switch>
  );
};

export { StaticRoutes, STATIC_AUTHLESS_URL_ROUTES };
