import invoke from './ServiceProxy';
import axios from 'axios';

const fetchBatteryDetails = async (locale, brand, vin, batterySerial) => {
  const baseUrl = `/batteryinfo/brand/${brand}?locale=${locale}`;
  const url = vin ? `${baseUrl}&vin=${vin}` : `${baseUrl}&batterySerial=${batterySerial}`;

  let response;
  try {
    response = await axios.get(url);
    response = response?.data || response;
  } catch (err) {
    throw new Error(err);
  }
  return response;
};

const downloadPSD = async psdlink => {
  const url = `/batteryinfo/carb/psdlink/${psdlink}`;
  let response;
  try {
    response = await axios.get(url, { responseType: 'blob' });
    response = (response?.data && response?.data?.data) || response;
  } catch (err) {
    throw new Error(err);
  }
  return response;
};

export { fetchBatteryDetails, downloadPSD };
