import React from 'react';
import LanguageToggle from '../../language_toggle/ZevLanguageToggle';
import carnetLogo from '../../../assets/images/vwlogo.png';
import './BatteryHeader.scss';
import { getLocaleSync } from '../../../providers/languageProvider';

const BatteryHeader = () => {
  const locale = getLocaleSync().locale;
  const countryCode = locale.split('-')[1];
  return (
    <header className='battery-header'>
      <img src={carnetLogo} alt='car-net-logo' width='130' className='car-net-logo' />
      <br />
      <div className='mt-2' />
      <div className='model'>
        <div className='battery-locale'>
          <LanguageToggle />
        </div>
        <br />
        <hr aria-hidden='true' className={`hr-line ${countryCode === 'US' ? 'us' : ''}`} />
        <span className='vw-logo' />
      </div>
    </header>
  );
};

export default BatteryHeader;
