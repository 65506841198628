/* eslint-disable complexity */
import React from 'react';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import { getEnrollmentStatusNonDestructive } from '../providers/GarageProvider';
import { getCarnetMarketingStaticInfo } from '../providers/SelfEnrollmentProvider';
import { UncontrolledAlert, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import defaultVehicleImage from '../../../assets/images/defaultVehicleImage.png';
import EnrollmentSummaryCA from './EnrollmentSummaryCA';
import PropTypes from 'prop-types';
import AutoLabelCheckbox from '../common/marketingConfiguration/AutoLabelCheckbox';
import { getCwpMeta } from '../../../providers/cwpMetaProvider';
import CARNET_CONSTANTS from '../../../constants/carnet_constants';

import {
  findApplicableTos,
  TOS_TYPE,
  TSP_PROVIDER,
  submitTosCarnet,
  submitTosCarnetVZT,
  TOS_STATUS,
  findEnrollmentProcedure,
  submitTosDataTrial
} from '../providers/SelfEnrollmentProvider';
import { setTitle } from '../../../providers/documentTitleProvider';
import Disclaimer from '../buyPlansNew/components/Disclaimer';

class TosCarnetCanada extends React.Component {
  state = {
    alert: null,
    processing: false,
    tosStatus: null,
    isOpenTosContentModal: false,
    tosContent: null,
    tosVersion: null,
    tspAccountNum: null,
    tspPIN: null,
    pinVisible: false,
    showDeclineConfirmation: false,
    showAgeConfirmation: false,
    displayConfirmationSummary: false,
    tosHasBeenRead: false,
    enrollmentStatus: null,
    staticMarketingInfo: null,
    tosUrl: null,
    displayDataTrial: false,
    defaultMno: null,
    typesOfTOS: [],
    dataTrialHotspotTos: null,
    dataTrialPrivacyTos: null,
    tosVersionDataTrial: null,
    marketingConfiguration: null,
    marketingConfigurationConsentCheckbox: null,
    providerImageLogo: null,
    checkboxValues: null,
    disclaimerText: null,
    disclaimerNumber: null,
    disclaimerTargetDomNode: null
  };

  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.fetchTosContent();
    this.fetchEnrollmentProcedureDetails();
    this.initMarketingConfigurations();
  };

  componentWillReceiveProps (props) {
    if (props.locale !== this.props.locale) {
      this.fetchTosContent();
    }
  }

  fetchTosContent = async () => {
    this.setState({ processing: true });
    try {
      const { tosVersion, tosContent, tosUrl, fullTosUrl } = await findApplicableTos(TOS_TYPE.CARNET);
      const enrollmentStatus = await getEnrollmentStatusNonDestructive();
      const staticMarketingInfo = await getCarnetMarketingStaticInfo();

      this.setState({
        tosVersion,
        tosContent,
        enrollmentStatus,
        staticMarketingInfo,
        tosUrl: fullTosUrl,
        processing: false
      });
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  fetchEnrollmentProcedureDetails = async () => {
    this.setState({ processing: true });
    try {
      const { defaultMno, typesOfTOS } = await findEnrollmentProcedure();
      const dataTrial = await findApplicableTos(TOS_TYPE.DATA_TRIAL);
      const tosVersionDataTrial = dataTrial?.tosVersion;
      this.setState({ defaultMno, typesOfTOS, tosVersionDataTrial });
    } catch (error) {
      this.setState({ alert: findErrorMessage(error, this.translator) });
    } finally {
      this.setState({ processing: false });
    }
  };

  initMarketingConfigurations = async () => {
    try {
      const { configurations } = await getCarnetMarketingStaticInfo();
      const metaResponse = await getCwpMeta();
      if (Array.isArray(configurations)) {
        const marketingConfiguration = configurations.find(
          c => c.subCategory?.toLowerCase() === 'trial' && c?.providerCode === CARNET_CONSTANTS.MNO_PROVIDER_ROGERS
        );

        const marketingConfigurationConsentCheckbox = AutoLabelCheckbox.parseValuesFromConsentCheckbox(
          marketingConfiguration?.consentCheckbox
        );

        let imageContentURL = metaResponse.static.content.urls.estore;
        let providerImageLogo = imageContentURL + marketingConfiguration?.providerLogo;
        this.setState({ marketingConfiguration, marketingConfigurationConsentCheckbox, providerImageLogo });
      }
    } catch (error) {
      console.log(error);
    }
  };

  parseTosContent = () => {
    const parser = new HtmlToReactParser();

    return parser.parse(this.state.tosContent);
  };

  // helper function to run on rendered html
  // need to hijack <anchor></anchor> tags because of react-router
  parseAndReplaceAnchors = () => {
    setTimeout(() => {
      let el = document.getElementById('tosBodyWrapper');
      let anchors = (el && el.querySelectorAll('a')) || [];
      anchors.forEach(element => {
        let href = element.getAttribute('href');
        let isHash = href && String(href).includes('#');
        let partsArr = isHash && String(href).split('#');
        let divId = null;
        if (Array.isArray(partsArr) && partsArr[0] === '' && partsArr[1]) {
          divId = partsArr[1];
        }
        let div = divId ? document.getElementById(divId) : null;
        if (divId && div) {
          element.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            div.scrollIntoView();
          });
          if (isHash) {
            element.setAttribute('href', '#');
          }
        }
      });

      // Removing the image reference tags
      let images = (el && el.querySelectorAll('img')) || [];
      images.forEach(img => {
        img.remove();
      });
    }, 250);
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  setTosStatus = tosStatus => {
    this.setState({ tosStatus });
  };

  checkAcceptanceThanSubmit = () => {
    if (this.state.tosStatus === TOS_STATUS.ACCEPT) {
      return this.setState({ showAgeConfirmation: true });
    }
    this.setState({ showDeclineConfirmation: true });
  };

  confirmAgeAndSubmit = () => {
    if (this.state.typesOfTOS.includes(TOS_TYPE.DATA_TRIAL)) {
      this.setState({ showAgeConfirmation: false, displayConfirmationSummary: false, displayDataTrial: true });
    } else {
      this.setState({ showAgeConfirmation: false, displayConfirmationSummary: true, displayDataTrial: false });
    }
  };

  confirmDeclinationAndContinue = () => {
    this.setState({ showDeclineConfirmation: false }, this.submitTos);
  };

  cancelDeclinationAndContinue = () => {
    this.setState({ showDeclineConfirmation: false });
  };

  submitTos = async () => {
    this.setState({ processing: true, alert: null });

    let response = null,
      submitResponseDataTrial = null;
    try {
      if (this.props.tspProvider === TSP_PROVIDER.VZT) {
        response = await submitTosCarnetVZT(
          this.state.tosVersion,
          this.state.tosStatus,
          this.state.tspAccountNum,
          this.state.tspPIN
        );
      } else {
        response = await submitTosCarnet(this.state.tosVersion, this.state.tosStatus);
        if (this.state.dataTrialHotspotTos && this.state.dataTrialPrivacyTos) {
          submitResponseDataTrial = await submitTosDataTrial(
            this.state.dataTrialHotspotTos,
            this.state.tosVersionDataTrial
          );
        }
      }

      this.setState({ processing: false });
      this.props.afterSubmit(this.state.tosStatus, response, submitResponseDataTrial);
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  navigateToEnrollmentSummary = () => {
    this.setState({ displayDataTrial: false, displayConfirmationSummary: true });
  };

  showDisclaimer = (disclaimerText, disclaimerNumber, disclaimerTargetDomNode) =>
    this.setState({ disclaimerText, disclaimerNumber, disclaimerTargetDomNode });

  toggleTosContentModal = () => {
    this.setState({ isOpenTosContentModal: !this.state.isOpenTosContentModal });
  };

  onScrollTos = () => {
    var element = document.getElementById('tosBodyWrapper');
    // added 5px as the scrolling will never quite match the actual clientHeight
    // this is affected by styling etc of wrapping element, so modify carefully
    let elementClientHeightToScroll = element.clientHeight + 5;
    let elementScrollPosition = element.scrollHeight - element.scrollTop;
    // console.log('scrolling tos ' + elementScrollPosition + ' vs ' + elementClientHeightToScroll);
    if (elementScrollPosition <= elementClientHeightToScroll) {
      this.setState({ tosHasBeenRead: true });
    }
  };

  /*
  getRadioClassName = status => {
    let className = !this.state.tosHasBeenRead ? 'disabled' : '';
    className = className + ' cwpRadio';

    if (status === this.state.tosStatus) {
      className = className + ' cwpRadioOn';
    }

    return className;
  };
  */

  backToTerms = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ displayConfirmationSummary: false, dataTrialHotspotTos: null, dataTrialPrivacyTos: null });
  };

  isInvalidVztEnrollmentSubmission = () => {
    return this.props.enrollmentProcedure === 'VZT' && (!this.state.tspAccountNum || !this.state.tspPIN);
  };

  onBackClick = () => window.history.back();

  render () {
    if (this.state.displayConfirmationSummary) {
      setTitle('document_titles.self_enrollment.vehicle_summary');
    } else {
      setTitle('document_titles.self_enrollment.terms_of_service');
    }

    const imgUrl = this.props.imgURL || defaultVehicleImage;
    const vehicleName = this.props.modelYear + ' ' + this.props.modelName;

    return (
      <>
        <div className='enrollmentFullWidthLeft'>
          {!this.state.displayConfirmationSummary && (
            <span className='cwpBack' onClick={this.onBackClick} role='button' tabIndex={0}>
              {this.translator.t('button.back')}
            </span>
          )}
        </div>
        {this.getAlerts()}
        {!this.state.displayConfirmationSummary && !this.state.displayDataTrial && (
          <>
            <h1 className='font-weight-normal'>
              {this.translator.t('tos_carnet.your')} <strong>{this.translator.t('tos_carnet.vehicle')}</strong>
            </h1>

            <div className='text-center'>
              <img className='mb-3' src={imgUrl} alt={''} width='300' />
              <span className='cwpCol'>
                <h2 className='font-weight-normal'>{vehicleName}</h2>
                <strong className='mb-3'>
                  {this.translator.t('tos_carnet.vin')}: {this.props.vin}
                </strong>
                {this.props.enrollmentProcedure === 'VZT' ? (
                  <span className='d-block'>
                    <div className='form-group'>
                      <label className='mb-3' htmlFor='tspAccountNum'>
                        {this.translator.t('tos_carnet.account_number')}
                      </label>
                      <input
                        id='tspAccountNum'
                        type='text'
                        className='cwp'
                        maxLength='9'
                        autoComplete={false}
                        pattern='[0-9]{9}'
                        required='true'
                        onChange={e => this.setState({ tspAccountNum: e.currentTarget.value })}
                      />
                    </div>
                    <div className='form-group'>
                      <label className='mb-3' htmlFor='tspPin'>
                        {this.translator.t('tos_carnet.pin')}
                      </label>
                      <input
                        id='tspPin'
                        type={this.state.pinVisible ? 'text' : 'password'}
                        className='cwp'
                        maxLength='4'
                        autoComplete={false}
                        required={true}
                        pattern='[0-9]{4}'
                        title={this.translator.t('pin_prompt.4_digit_number_only')}
                        onChange={e => this.setState({ tspPIN: e.currentTarget.value })}
                      />
                      <span
                        className={this.state.pinVisible ? 'cwpInputEye cwpInputEyeOpen' : 'cwpInputEye'}
                        onClick={e => this.setState({ pinVisible: !this.state.pinVisible })}
                      />
                    </div>
                  </span>
                ) : (
                  ''
                )}
              </span>
            </div>

            <div className='col-md-8 text-left mx-auto my-5'>
              <h2>{this.translator.t('tos_carnet.terms_of_service')}</h2>
              <span className='tos_subhead_instrux'>{this.translator.t('tos_carnet.you_must_scroll_instrux')}</span>
              <div className='tosContainerCA' aria-live='polite'>
                <div className='innerWrapper' id='tosBodyWrapper' onScroll={this.onScrollTos}>
                  {this.parseTosContent()}
                  {this.parseAndReplaceAnchors()}
                </div>
              </div>
              <p>{this.translator.t('tos_carnet.read_accept_vw_tos')}</p>
              {/* 
              <span
                disabled={!this.state.tosHasBeenRead}
                className={this.getRadioClassName(TOS_STATUS.ACCEPT)}
                tabIndex={0}
                role='radio'
                onClick={() => {
                  if (!this.state.tosHasBeenRead) {
                    return;
                  }
                  this.setTosStatus(TOS_STATUS.ACCEPT);
                }}
                onKeyPress={e => {
                  if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                    if (!this.state.tosHasBeenRead) {
                      return;
                    }
                    this.setTosStatus(TOS_STATUS.ACCEPT);
                  }
                }}
                aria-labelledby='spanAccept'
              >
                <span id='spanAccept'>{this.translator.t('tos_carnet.accept')}</span>
              </span>
              <br />
              <span
                disabled={!this.state.tosHasBeenRead}
                className={this.getRadioClassName(TOS_STATUS.DECLINE)}
                tabIndex={0}
                role='radio'
                onClick={() => {
                  if (!this.state.tosHasBeenRead) {
                    return;
                  }
                  this.setTosStatus(TOS_STATUS.DECLINE);
                }}
                onKeyPress={e => {
                  if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                    if (!this.state.tosHasBeenRead) {
                      return;
                    }
                    this.setTosStatus(TOS_STATUS.DECLINE);
                  }
                }}
                aria-labelledby='spanDecline'
              >
                <span id='spanDecline'>{this.translator.t('tos_carnet.decline')}</span>
              </span>
            */}
              <fieldset>
                <div
                  className='radioCwpOverwriteWrapper'
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (this.state.tosHasBeenRead) {
                      this.setTosStatus(TOS_STATUS.ACCEPT);
                    }
                  }}
                >
                  <input
                    aria-label={this.translator.t('tos_carnet.accept')}
                    id='rdoOptionAccept'
                    type='radio'
                    name='TOS_STATUS'
                    checked={this.state.tosStatus === TOS_STATUS.ACCEPT}
                    className={`radioCwpOverwrite ${this.state.tosHasBeenRead ? '' : 'disabled'}`}
                    disabled={!this.state.tosHasBeenRead}
                    onChange={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setTosStatus(TOS_STATUS.ACCEPT);
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (this.state.tosHasBeenRead) {
                        this.setTosStatus(TOS_STATUS.ACCEPT);
                      }
                    }}
                    onKeyPress={e => {
                      if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                        if (this.state.tosHasBeenRead) {
                          this.setTosStatus(TOS_STATUS.ACCEPT);
                        }
                      }
                    }}
                    value={TOS_STATUS.ACCEPT}
                  />
                  <label className='radioCwpOverwriteLabel' htmlFor='rdoOptionAccept'>
                    {this.translator.t('tos_carnet.accept')}
                  </label>
                </div>

                <div
                  className='radioCwpOverwriteWrapper'
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (this.state.tosHasBeenRead) {
                      this.setTosStatus(TOS_STATUS.DECLINE);
                    }
                  }}
                >
                  <input
                    aria-label={this.translator.t('tos_carnet.decline')}
                    id='rdoOptionDecline'
                    type='radio'
                    name='TOS_STATUS'
                    checked={this.state.tosStatus === TOS_STATUS.DECLINE}
                    className={`radioCwpOverwrite ${this.state.tosHasBeenRead ? '' : 'disabled'}`}
                    disabled={!this.state.tosHasBeenRead}
                    onChange={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setTosStatus(TOS_STATUS.DECLINE);
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setTosStatus(TOS_STATUS.DECLINE);
                      if (this.state.tosHasBeenRead) {
                        this.setTosStatus(TOS_STATUS.DECLINE);
                      }
                    }}
                    onKeyPress={e => {
                      if (e.key && (/enter/gi.test(e.key.toString()) || e.key === ' ')) {
                        if (this.state.tosHasBeenRead) {
                          this.setTosStatus(TOS_STATUS.DECLINE);
                        }
                      }
                    }}
                    value={TOS_STATUS.DECLINE}
                  />
                  <label className='radioCwpOverwriteLabel' htmlFor='rdoOptionDecline'>
                    {this.translator.t('tos_carnet.decline')}
                  </label>
                </div>
              </fieldset>
            </div>
            <div className='tos-cont-btn-wrapper'>
              <button
                className='cwp mb-5'
                onClick={this.checkAcceptanceThanSubmit}
                disabled={this.state.processing || !this.state.tosStatus || this.isInvalidVztEnrollmentSubmission()}
              >
                {this.translator.t('tos_carnet.continue')}
              </button>
            </div>
          </>
        )}

        {this.state.displayConfirmationSummary && (
          <EnrollmentSummaryCA
            imgUrl={imgUrl}
            vin={this.props.vin}
            enrollmentStatus={this.state.enrollmentStatus}
            vehicleName={vehicleName}
            staticMarketingInfo={this.state.staticMarketingInfo}
            backAction={this.backToTerms}
            finalEnrollmentAction={this.submitTos}
            processing={this.state.processing}
            tosUrl={this.state.tosUrl}
            dataTrialConfiguration={this.state.marketingConfiguration}
            dataTrialHotspotTos={this.state.dataTrialHotspotTos}
            dataTrialPrivacyTos={this.state.dataTrialPrivacyTos}
          />
        )}

        {this.state.displayDataTrial && (
          <div className='tosDataTrial'>
            {this.getAlerts()}
            <div className='h3'>
              {this.translator.t('self_enrollment.data_trial.access_wifi_directly')}
              <span className='font-weight-bold'>
                {this.translator.t('self_enrollment.data_trial.from_your_vehicle')}
              </span>
            </div>
            <div className='col-md-5 text-left mx-auto my-5 d-flex flex-column'>
              <div className='h4 font-weight-bold'>{this.translator.t('self_enrollment.data_trial.Wifi_trial')}</div>
              <div className='mt-3 mb-3 lead'>
                <article className='mb-3 lead'>
                  {this.state.marketingConfiguration?.description}
                  {this.state.marketingConfiguration?.summary?.disclaimerNumber && (
                    <button
                      className='cwp-disclaimer'
                      onClick={e =>
                        this.showDisclaimer(
                          this.state.marketingConfiguration?.disclaimerTextList?.[0]?.disclaimerText,
                          this.state.marketingConfiguration?.summary?.disclaimerNumber,
                          e.currentTarget
                        )
                      }
                    >
                      {this.state.marketingConfiguration?.summary?.disclaimerNumber}
                    </button>
                  )}
                </article>
                <hr className='hrLine w-100' />
                <img className='mt-2' src={this.state.providerImageLogo} alt='' width='111' height='22' />
                <div className='h3 font-weight-bold mt-4'>
                  {this.translator.t('self_enrollment.data_trial.terms_of_service')}
                </div>
                <div className='h5 font-weight-bold mt-4'>
                  {this.translator.t('self_enrollment.data_trial.review_tos')}
                </div>
              </div>
              {Array.isArray(this.state.marketingConfigurationConsentCheckbox) && (
                <>
                  <div className='my-1'>
                    <AutoLabelCheckbox
                      value={this.state.marketingConfigurationConsentCheckbox?.[0]}
                      translator={this.translator}
                      onClickHyperLink={() => {}}
                      unClickedLinks={[]}
                      onChange={dataTrialHotspotTos => this.setState({ dataTrialHotspotTos })}
                      disableCheckboxUntilAllLinksClicked={true}
                    />
                  </div>
                  <div className='my-2'>
                    <AutoLabelCheckbox
                      value={this.state.marketingConfigurationConsentCheckbox?.[1]}
                      translator={this.translator}
                      onClickHyperLink={() => {}}
                      unClickedLinks={[]}
                      onChange={dataTrialPrivacyTos => this.setState({ dataTrialPrivacyTos })}
                      disableCheckboxUntilAllLinksClicked={true}
                    />
                  </div>
                </>
              )}

              <div className='mt-5 mb-4 tos-cont-btn-wrapper'>
                <button className='cwpSecondary maybeLaterBtn' onClick={this.navigateToEnrollmentSummary}>
                  {this.translator.t('self_enrollment.data_trial.maybe_later')}
                </button>
                <button
                  className='cwp ms-3'
                  disabled={!(this.state.dataTrialHotspotTos && this.state.dataTrialPrivacyTos)}
                  onClick={this.navigateToEnrollmentSummary}
                >
                  {this.translator.t('self_enrollment.data_trial.activate_trial')}
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.disclaimerText && (
          <Disclaimer
            translator={this.translator}
            disclaimerText={this.state.disclaimerText}
            disclaimerNumber={this.state.disclaimerNumber}
            disclaimerTargetDomNode={this.state.disclaimerTargetDomNode}
            onExit={() => {
              if (this.state.disclaimerTargetDomNode) {
                this.state.disclaimerTargetDomNode.focus();
              }
              this.setState({ disclaimerText: null, disclaimerNumber: null, disclaimerTargetDomNode: null });
            }}
          />
        )}

        <Modal
          isOpen={this.state.showDeclineConfirmation}
          className='carnet-sg-modal tosDeclinationConfirmation'
          aria-live='polite'
        >
          <ModalHeader>{''}</ModalHeader>
          <ModalBody>{this.translator.t('tos_carnet.decline_confirmation')}</ModalBody>
          <ModalFooter>
            <div>
              <Button
                className='modal-primary-btn'
                color='modal_background_color'
                onClick={this.confirmDeclinationAndContinue}
              >
                {this.translator.t('button.ok')}
              </Button>
            </div>
            <div>
              <Button
                className='modal-secondary-btn'
                color='modal_background_color'
                onClick={this.cancelDeclinationAndContinue}
              >
                {this.translator.t('button.cancel')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.showAgeConfirmation}
          className='carnet-sg-modal tosDeclinationConfirmation'
          aria-live='polite'
        >
          <ModalHeader>{this.translator.t('tos_carnet.age_confirmation_modal_title')}</ModalHeader>
          <ModalBody>{this.translator.t('tos_carnet.age_confirmation')}</ModalBody>
          <ModalFooter>
            <div>
              <Button className='modal-primary-btn' color='modal_background_color' onClick={this.confirmAgeAndSubmit}>
                {this.translator.t('button.ok')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  static propTypes = {
    locale: PropTypes.string.isRequired
  };
}

export default TosCarnetCanada;
