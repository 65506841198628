import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './redux/actions';
import { getLocaleTranslator, setPreferredLanguage } from './util/i18n/i18nService';
import './App.scss';
import Footer from './components/carnet/theme/Footer';
import Tos from './components/carnet/theme/Tos';
import NavBar from './components/carnet/theme/Navbar';
import CarnetHome from './components/carnet/CarnetHome';
import DriveViewEnrollment from './components/carnet/acctMgmt/DriveViewEnrollment';
// import BuyPlans from './components/carnet/buyPlans/buyPlansLanding';
import Features from './components/carnet/theme/Features';
import VehicleHistoryInfo from './components/carnet/vehicleHistoryInfo/vehicleHistoryInfo';
import UsernameEditor from './components/carnet/acctMgmt/UsernameEditor';
import VehicleHealth from './components/carnet/vehicleHealth/VehicleHealthRolesCheck';
import ViewSXMDetails from './components/carnet/vehicleHealth/ViewSXMDetails';
import VSCUnenroll from './components/carnet/acctMgmt/VSCUnenroll';
import VSCEnroll from './components/carnet/acctMgmt/VSCEnroll';
import AccountManagement from './components/carnet/acctMgmt/AccountManagement';
import ServicesEditor from './components/carnet/acctMgmt/ServicesEditor';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import ChangeServices from './components/carnet/garage/ChangeServices';
import SelfEnrollment from './components/carnet/self_enrollment/SelfEnrollment';
import withSpinEntry from './components/carnet/garage/WithSpinEntry';
import TosUBI from './components/carnet/self_enrollment/TosUBI';
import Video from './components/carnet/marketingVideo/VideoHome';
import MessageCenterInfo from './components/carnet/messageCenter/messageCenterInfo';
import { getCwpSessionInfo } from './providers/sessionProvider';
import ModelYr2014_19 from './components/carnet/landingPage2014_19/ModelYr2014_19';
import DisplayBatteryInfo from './components/carnet/batteryInfo/DisplayBatteryInfo';
/* 
import LandingPage from './components/carnet/landingPage/landingPage';
import DropDown from './components/carnet/planPricing/modelyearDropdown';
import EV from './components/carnet/landingPageEV/landingPageEV';
 */
import { restoreStartingLocation, getVehicleContext } from './providers/historyProvider';
import { clearVehicleLocaleContext } from './providers/languageProvider';
import Trips from './components/carnet/trips/TripStatsHome';
import MQBTripStats from './components/carnet/trips/MQBTripStats';

import './providers/documentTitleProvider';
import { getLocale } from './providers/languageProvider';
import { queryParamsOnApplicationLoad } from './providers/queryActionsProvider';
import { i18nCustomerLocale, registerLocaleChangeListener } from './util/i18n/i18nService';
import { StaticRoutes, STATIC_AUTHLESS_URL_ROUTES } from './StaticRoutes';
import SubscribedFetaureVzt, { Capability } from './components/carnet/common/SubscribedFetaureVzt';
import TOSCarnet from './components/carnet/garage/TOSCarnet';
import BuyPlansNew from './components/carnet/buyPlansNew';
import UnEnrollUBIDataShare from './components/carnet/acctMgmt/UnEnrollUBIDataShare';
import { VWCOM_CARNET_STATIC_CONTENT_URL } from './constants/carnet_constants';
import BatteryHeader from './components/carnet/batteryInfo/BatteryHeader';
const COMPLETE_STATIC_AUTHLESS_URLS = STATIC_AUTHLESS_URL_ROUTES.map(urlBit => `${window.location.origin}/#/${urlBit}`);

const CA_REDIRECT_URL = 'https://www.vw.ca/en/why-vw/innovation-and-technology/car-net.html';

const App = props => {
  const [SESSION_VALID, setSessionValid] = useState(null);

  const [LOCALE_SETTING, setAppLocale] = useState(i18nCustomerLocale);

  const [INITIAL_LOCALE_OBTAINED_ON_LOAD, setInitialLocaleOnLoad] = useState(null);

  const translator = getLocaleTranslator();

  let _isMounted = true;

  // triggers a change in the main app 'state', causing a re-render of all child components as that is passed via props
  registerLocaleChangeListener(locale => {
    if (LOCALE_SETTING !== locale) {
      setAppLocale(locale);
    }
  }, 'app_main_00');

  // observing the popstate, because the 'useEffect' below only happens on refresh and browser back/forward, not on copy/paste
  window.addEventListener('popstate', function (event) {
    if (SESSION_VALID) {
      // let restoredLocation = restoreStartingLocation();
      let vehicleContext = getVehicleContext();
      // there is a vehicle in context, go ahead and set it so that refresh locations can be refreshed
      if (vehicleContext) {
        console.log('there is a vehicle context on window location change');
        props.actions.setUserVehicleContext(vehicleContext);
      }
    }
  });

  const skipToMainContent = e => {
    e.preventDefault();
    e.stopPropagation();
    // look for element with id 'mainContent' and set focus on that if present
    let el = document.getElementById('main-content');
    if (el) {
      // found the element, set focus and scroll to it,
      // manually set tabIndex to -1 so that it gets keyboard focus but doesn't receive focus tabbing through
      el.tabIndex = -1;
      el.focus();
      el.scrollIntoView();
    }
    return false;
  };

  // utility method for extracting hash values from urls
  const getHashValueFromUrl = (urlStringParam = '') => {
    // ensure fallback is empty string
    let urlString = typeof urlStringParam === 'string' ? urlStringParam : '';
    let urlStringSplit = urlString.split('/#/');
    return (urlStringSplit[1] && urlStringSplit[1].trim()) || '';
  };

  // set user language for specific urls in app where locale is defined in url pattern
  useEffect(() => {
    // inspect url for faq sections where we ought to set the language
    let location = window.location.href;
    let localeInUrlPatterns = ['en/us', 'en/ca', 'fr/ca'];
    localeInUrlPatterns.forEach(key => {
      if (location.includes(key)) {
        let keyArr = key.split('/');
        if (_isMounted) {
          setPreferredLanguage({ userLanguage: keyArr[0], userCountry: keyArr[1] });
        }
      }
    });
    return () => {
      _isMounted = false;
    };
  }, []);

  useEffect(() => {
    const startUpAsync = async () => {
      // check for CA when no-session on load as they don't want to see the "home" screen (CNCA-1403)
      let localeInfo = await getLocale();
      // check for CA when no-session on load as they don't want to see the "home" screen (CNCA-1403)
      let country = localeInfo.userCountry.toUpperCase();
      if (_isMounted) {
        setInitialLocaleOnLoad(localeInfo);
      }

      return getCwpSessionInfo()
        .then(response => {
          setSessionValid(true);

          let finalWindowLocationHash = getHashValueFromUrl(window.location.href);
          // if vehicle context in place and a hash location (not garage), restore vehicle context
          let vehicleContext = getVehicleContext();
          if (vehicleContext && finalWindowLocationHash) {
            props.actions.setUserVehicleContext(vehicleContext);
          }

          // last location that was stored on popstate event
          let restoredLocationUrlString = restoreStartingLocation();
          // grabbing only the hash value
          let finalRestoredLocationHash = getHashValueFromUrl(restoredLocationUrlString);
          let baseUrl = window.location.origin;

          console.log(
            '\n\n vehicleContext truthiness is ' +
              !!vehicleContext +
              ' current windowHash is ' +
              finalWindowLocationHash +
              ' & saved windowHash is ' +
              finalRestoredLocationHash +
              '\n\n'
          );

          // only going into this if we have a location that was captured and it's not "home"
          if (restoredLocationUrlString && finalRestoredLocationHash !== 'home') {
            // both are hash values, go w/the window location as that is the user directed location
            if (finalWindowLocationHash && finalRestoredLocationHash) {
              return window.location.assign(`${baseUrl}/#/${finalWindowLocationHash}`);
            }

            // window location is a hash, but restored location is the garage and vehicle in context, go w/garage
            // seems confusing but also fighting the router in this case
            if (finalWindowLocationHash && !finalRestoredLocationHash && !!vehicleContext) {
              return window.location.assign(`${baseUrl}/#/${finalRestoredLocationHash}`);
            } else if (finalWindowLocationHash && !finalRestoredLocationHash) {
              // no vehicle in context allow passage to desired window location (manually entering address after getting into garage)
              return window.location.assign(`${baseUrl}/#/${finalWindowLocationHash}`);
            }

            // otherwise it isn't 'home' so just route to that
            return window.location.assign(`${baseUrl}/#/${finalRestoredLocationHash}`);
          }

          // country was CA, handle default routing differently (CNCA-1403)
          // no /#/home route
          if (country === 'CA') {
            // route to garage when user logged in as opposed to logging out to the CA_REDIRECT_URL
            return window.location.assign(window.location.origin + '/#/');
          }

          console.log('restoredLocation was /#/home, or no location to restore not restoring');
        })
        .catch(err => {
          console.log('got failure response for getCwpSessionInfo inside of main App component');

          setSessionValid(false);
          // clear out any remnants form previous session stuff
          window.localStorage.clear();
          clearVehicleLocaleContext();
          // unauthenticated redirect(s) to static content held elsewhere
          const url = window.location.href;
          let urlIdentifiedAsStatic = STATIC_AUTHLESS_URL_ROUTES.some(urlPartial => url.includes(urlPartial));
          if (!urlIdentifiedAsStatic) {
            if (country === 'CA') {
              window.location.assign(CA_REDIRECT_URL);
              return;
            }
            window.location.assign(VWCOM_CARNET_STATIC_CONTENT_URL);
          }
        });
    };

    queryParamsOnApplicationLoad().finally(startUpAsync);
    return () => {
      _isMounted = false;
    };
  }, []); // eslint-disable-line

  const hostname = window.location.hostname;
  const isBatteryInfoAccessAllowed = [
    'w-h-s.spr.us00.uat.con-veh.net',
    'w-h-s.spr.us00.int.con-veh.net',
    'w-h-s.spr.ca00.uat.con-veh.net',
    'localhost'
  ].some(allowedHost => hostname.includes(allowedHost));
  if (SESSION_VALID !== null && INITIAL_LOCALE_OBTAINED_ON_LOAD !== null)
    return (
      <div className='page-container'>
        <a href='#main-content' className='srSkipToMainContentLink' onClick={skipToMainContent}>
          {translator.t('SKIP_TO_MAIN_CONTENT')}
        </a>
        <div className='content-wrap'>
          <Router>
            <Switch>
              <Route
                exact
                path='/'
                render={() => <CarnetHome locale={LOCALE_SETTING} userAuthorized={SESSION_VALID} />}
              />{' '}
              {/*<Route
                exact
                path='/'
                component={() => {
                  window.location.href = `${window.location.origin}/login`;
                }}
              /> */}
              {/* CR-371 redirect */}
              <Route
                exact
                path='/home'
                component={() => {
                  window.location.href = VWCOM_CARNET_STATIC_CONTENT_URL;
                }}
              />
              <Route path='/selfEnrollment' render={() => <SelfEnrollment locale={LOCALE_SETTING} />} />
              <Route path='/changeServices' component={withSpinEntry(ChangeServices)} />
              <Route path='/carnetTOS/:vehicleId' locale={LOCALE_SETTING} component={TOSCarnet} />
              <Route
                path='/history'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <VehicleHistoryInfo locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route
                path='/buy-plans'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <BuyPlansNew locale={LOCALE_SETTING} />
                  </>
                )}
              />
              {/*
              <Route
                path='/buy-plans-depricated'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <BuyPlans locale={LOCALE_SETTING} />
                  </>
                )}
              />
              */}
              <Route
                path='/enroll-only-ubi-direct'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <TosUBI locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route
                path='/editUsername'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <UsernameEditor locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route
                path='/acct-mgmt'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <AccountManagement locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route path='/driveview-enrollment/:contextVin' component={DriveViewEnrollment} />
              <Route
                path='/vsc-unenroll'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <VSCUnenroll locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route
                path='/vsc-enroll'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <VSCEnroll locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route
                path='/driveview-unenroll-items'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    {/*<ServicesEditor locale={LOCALE_SETTING} /> */}
                    <UnEnrollUBIDataShare />
                  </>
                )}
              />
              <Route
                path='/vhr'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <SubscribedFetaureVzt enableForAny={[Capability.STD_SERVICES]}>
                      <VehicleHealth locale={LOCALE_SETTING} />
                    </SubscribedFetaureVzt>
                  </>
                )}
              />
              <Route
                path='/trips'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <Trips locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route
                path='/mqbtrips'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <MQBTripStats locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route
                path='/sxmdetails'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <ViewSXMDetails locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route
                path='/tos'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <Tos locale={LOCALE_SETTING} />
                  </>
                )}
              />
              <Route
                path='/marketing-video'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Video locale={LOCALE_SETTING} />
                  </>
                )}
              />
              {/* CR-371 redirect */
              /*INITIAL_LOCALE_OBTAINED_ON_LOAD.userCountry.toLowerCase() !== 'ca' && (
                <Route
                  path='/home'
                  render={() => (
                    <>
                      <LandingPage locale={LOCALE_SETTING} />
                    </>
                  )}
                />
              ) */}
              {/*
              // decommisioned message center - SUP-9091
              <Route
                path='/messagecenter'
                render={() => (
                  <>
                    <NavBar locale={LOCALE_SETTING} />
                    <Features locale={LOCALE_SETTING} />
                    <MessageCenterInfo locale={LOCALE_SETTING} />
                  </>
                )}
              />
              */}
              {/* <Route path='/plan-pricing/:modelYear' component={DropDown} /> */}
              {/* CR-371 redirect */}
              <Route
                path='/plan-pricing/:modelYear'
                component={() => {
                  window.location.href = VWCOM_CARNET_STATIC_CONTENT_URL;
                }}
              />
              {/* <Route path='/plan-pricing' component={DropDown} /> */}
              {/* CR-371 redirect */}
              <Route
                path='/plan-pricing'
                component={() => {
                  window.location.href = VWCOM_CARNET_STATIC_CONTENT_URL;
                }}
              />
              <Route
                path='/modelyr2014-2019'
                render={() => (
                  <>
                    <ModelYr2014_19 locale={LOCALE_SETTING} />
                  </>
                )}
              />
              {/*<Route
                path='/electric-vehicles'
                render={() => (
                  <>
                    <EV locale={LOCALE_SETTING} />
                  </>
                )}
              /> */}
              {/* CR-371 redirect */}
              <Route
                path='/electric-vehicles'
                component={() => {
                  window.location.href = VWCOM_CARNET_STATIC_CONTENT_URL;
                }}
              />
              {isBatteryInfoAccessAllowed && (
                <Route
                  path='/zev/(vw|audi|bentley)/batteryinfo'
                  render={() => (
                    <>
                      <BatteryHeader />
                      <DisplayBatteryInfo />
                    </>
                  )}
                />
              )}
            </Switch>
            <StaticRoutes locale={LOCALE_SETTING} />
            <Footer locale={LOCALE_SETTING} />
          </Router>
        </div>
      </div>
    );
  return (
    <div className='preLoadingBlock'>
      <div className='App-logo'></div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setUserVehicleContext: actions.setUserVehicleContext
      },
      dispatch
    )
  };
};

export { App };
export default connect(null, mapDispatchToProps)(App);
